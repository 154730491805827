@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%; /* Default width for all devices */

  @media (max-width: 600px) {
    width: 100%; /* Ensure full width on small screens */
    padding: 0;  /* Remove any padding if needed */
    margin: 0;   /* Remove any margin if needed */
  }
}