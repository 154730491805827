@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
.home {  
    .header-container {
      position: relative;
      width: 100%;
      height: 808px;
      margin: 0;
      @media (max-width: 600px) {
        display: none; // Hide the image on screens smaller than 600px
      }
  
      .header-image {
        width: 100%;
        max-height: 808px;
        object-fit: cover;
        margin: 0;
      }
  
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: white;
        background: rgba(0, 0, 0, 0.2); 
  
        .logo {
            position: absolute;
            top: -60px;
            left: 40px;
            width: 250px;
            height: 140px;
            z-index: 10001;
            opacity: 0; // Start with logo hidden
            animation: fadeIn 2s forwards; // Apply the animation on load

            @media (max-width: 600px) {
              display: none; // Hide the logo on screens smaller than 600px
            }
          }
  
        h1 {
            font-family: 'Inter', sans-serif;
            font-size: 2rem;
            margin-left:210px;
            margin-top: 280px;
            align-items: center;
            text-transform: uppercase;
        }
  
        p {
          font-family: 'Inter', sans-serif;
          font-size: 1.2rem;
          font-weight: 600;
          background-color: white;
          margin-left:210px;
          text-transform: uppercase;
          color: rgba($color: #323030, $alpha: 1.0);
        }
  
        .cta-button {
          background-color: #ff0000; // Red color
          color: white;
          padding: 10px 20px;
          border: none;
          font-size: 1rem;
          cursor: pointer;
          margin-top: 20px;
          margin-left:210px;
          text-transform: uppercase;
          letter-spacing: 1px;
  
          &:hover {
            background-color: #cc0000; // Darker red on hover
          }
        }
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .despre-zbor{
    margin-left: 100px;
    width: 45%;
    height: auto;
  }