.contact-section {
  padding: 20px;

  .section-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .contact-container {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-grid-item {
    display: flex;
    flex-direction: column;
  }

  .contact-info,
  .contact-form {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; // Ensures content is evenly spaced
    height: 100%;
    gap: var(--spacing-medium);
  }

  .contact-info {
    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }
    }

    .schedule {
      margin-top: 20px;
    }
  }

  .contact-form {
    form {
      display: flex;
      flex-direction: column;

      .MuiTextField-root {
        margin-bottom: 15px;
      }

      .MuiButton-root {
        margin-top: 20px;
      }
    }
  }
}
