.image-section {
    width: 100%;
    height: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    top: 0;
    margin-top: 0;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }