.footer {
  background-color: #f9f9f9;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-large);

  .footer-container {
    max-width: 1600px;
    width: 100%;
  }

  .footer-logo-section,
  .footer-partners-section,
  .footer-menu-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; // Center the content vertically
    align-items: center;

    .footer-logo {
      width: 168px;
      margin-bottom: 10px;
    }

    .social-media {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin: 10px 0;
    }

    .copyright {
      margin-top: 10px;
    }
  }

  .footer-partners-section {
    .partners-logos {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
      img {
        height: 40px;
        width: auto;
      }
    }
  }

  .footer-menu-section {
    align-items: center;
    text-align: left;
    .footer-menu-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start; // Align text to the left
        justify-content: center; // Center the content vertically within the column
        height: 100%;
      }
    .footer-menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: left;

      li {
        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}
