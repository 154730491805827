@import '../../variables.scss';

.how-it-works-mobile{
  background-color: #FBFAFA;
  padding: 20px;
  min-height: 100vh;
  text-align: center; 

  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;      
    align-items: left;
    margin-bottom: 25px;
  }
  .steps {
    @media (max-width: 600px) {
      flex-direction: column; // Switch to vertical layout for mobile
      align-items: center;
    }
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 0 20px;

      .icon {
        font-size: 40px;
        margin-bottom: 10px;
      }
      .phone{
          margin: 0;
          padding: 0;
      }
      p {
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
      }
      .telefonic{
          max-width: 200px;
          word-wrap: break-word;
          text-align: center;
        }
    }

    .separator {
     display: none;
    }
  }
  .more-info-container {
      display: flex;
      width: 100%;
    }
  .more-info-button-mobile {
      font-family: 'Inter', sans-serif;
      align-items: center;
      width: 100%; 
    }
}
.how-it-works {
    margin-top: var(--margin-top);
    background-color: #FBFAFA;
    padding: 20px;
    text-align: center; 
  
    h2 {
      font-family: 'Inter', sans-serif;
      font-size: 1.5rem;      
      align-items: left;
      // margin-left: var(--left-spacing);
      margin-bottom: 25px;
    }
  
    .steps {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      // margin-left: var(--left-spacing);
    //   margin-right: var(--right-spacing);
      padding: 10px;

      @media (max-width: 600px) {
        flex-direction: column; // Switch to vertical layout for mobile
        align-items: center;
      }
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        padding: 0 20px;
  
        .icon {
          font-size: 40px;
          margin-bottom: 10px;
        }
        .phone{
            margin: 0;
            padding: 0;
        }
        p {
          font-family: 'Inter', sans-serif;
          font-size: 1rem;
        }
        .telefonic{
            max-width: 200px;
            word-wrap: break-word;
            text-align: center;
          }
      }
  
      .separator {
        width: 40px;
        height: 1px;
        background-color: black;
        margin: 0 20px;

        @media (max-width: 600px) {
          width: 50px; // Adjust separator width for mobile
          height: 2px; // Horizontal line instead of vertical
        }
      }
    }
    .more-info-container {
        display: flex;
        justify-content: center;
        // margin-right: var(--right-spacing);
        margin-top: var(--spacing-medium);
      }
    .more-info-button {
        margin-top: var(--spacing-medium);
        font-family: 'Inter', sans-serif;
        // margin-left: var(--left-spacing);
        align-items: center;

      }
  }