@import '../../variables.scss';

.media-gallery {
  background-color: #f9f9f9;
  padding: var(--spacing-medium);
  border-radius: 8px;

.header-title{
  margin-left: 100px;
}
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    // margin-bottom: var(--spacing-medium);
    margin-left: 10px;
    align-items: left;

    span {
      margin-right: var(--spacing-small);
    }
  }
  .icon {
    margin-right: 10px;
  }
  .gallery-container{
    display: flex;
    padding: 30px;
    margin-left: var(--left-spacing);
    margin-right: var(--right-spacing);
  }

  .slick-slide {
    padding: var(--spacing-small);

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .arrow {
    font-size: 2rem;
    cursor: pointer;
  }

  .prev {
    left: -25px;
  }

  .next {
    right: -25px;
  }
}