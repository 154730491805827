@import "../../shared/theme/colors";

.offer-cards-container {
  background-color: #fe0505;
  padding: 20px;
  color: white;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 60%;
  left: 70%;
  transform: translateX(-50%);
  width: 600px;
  height: 586px;
  opacity: 0.9;
  border-radius: 5px;
  margin-left: 90px;

  .title {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  .cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 1rem;
    border-radius: 5px;
  }

  .card {
    background-color: #fe0505;
    color: black;
    width: 200px;
    height: 200px;
    padding: 1rem 0;
    border-radius: 5px;
    perspective: 1000px; // To enable the 3D flip effect

    .card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    &:hover .card-inner {
      transform: rotateY(180deg);
    }

    .card-front,
    .card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }

    .card-front {
      background-color: #ed0707;
      color: white;
      // padding: 20px;

      .card-title {
        font-size: 1.2rem;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      .card-price {
        font-size: 1rem;
      }
    }

    .card-back {
      background-color: #fff;
      color: black;
      transform: rotateY(180deg);
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
        z-index: 1;
      }

      .card-description {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        // padding: 10px;
        border-radius: 5px;
        z-index: 2;

        .card-back-title {
          width: 100%;
          background-color: #fff;
          padding: 5px;
          top: 0;
          color: rgba(0, 0, 0, 0.5);
          text-transform: uppercase;
          position: sticky;
        }
        .card-back-description {
          display: flex;
          align-items: left;
          justify-content: flex-start;
          gap: 5px;
        }

        .card-subtitle {
          font-size: 0.8rem;
          margin-bottom: 5px;
        }

        .add-to-cart-button {
          margin-top: 10px;
          color: white;
        }
        .left-text {
          text-align: left;
          margin-left: 10px;
        }
        .icon {
          font-size: 1rem;
          margin-right: 5px;
        }
      }
    }
  }
}

.offer-cards-mobile-container {
  background-color: $white;
  color: white;
  text-align: center;
  margin: 0 auto;
  // position: absolute;
  top: 30%;
  width: 100%;
  opacity: 0.9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-mobile {
    color: $textDark;
  }
  .cards-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin: 10px;

    .card-mobile {
      width: 100%;
      max-width: 100%;
      max-width: 300px; // Ensures consistent width
      height: 200px; // Ensures consistent height
      background-size: cover; // Ensures the background image covers the entire card
      background-position: center; // Centers the background image
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      align-items: flex-end; // Align content at the bottom
    }
    .card-content-mobile {
      background-color: rgba(0, 0, 0, 0.5); // Semi-transparent overlay for text
      border-radius: 5px;
      width: 100%;
      text-align: center;
      color: white;
      flex-grow: 1;

      .card-title {
        font-size: 1.25rem;
        margin-bottom: 10px;
      }

      .card-price {
        font-size: 1.15rem;
        margin-bottom: 12px;
      }

      .add-to-cart-button-mobile {
        margin-top: 5.2rem;
        width: 100%;
        color: white;
        padding: 8px 16px;
        text-transform: none;
        align-self: center; // Center the button horizontally
      }
    }
  }
}
