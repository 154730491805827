.hero-home {
  .hero-header-container {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    background: rgba(0, 0, 0, 0.2);

    h1 {
      font-family: "Inter", sans-serif;
      font-size: 2rem;
      margin-left: 210px;
      margin-top: 280px;
      align-items: center;
      text-transform: uppercase;
    }
    p {
      font-family: "Inter", sans-serif;
      font-size: 1.2rem;
      font-weight: 600;
      background-color: white;
      margin-left: 210px;
      text-transform: uppercase;
      color: rgba($color: #323030, $alpha: 1);
    }
  }
}
