.menu {
  width: 100%;
  position: relative;
  .MuiAppBar-root {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Adding shadow to the AppBar
  }

  .menuButton {
    margin-right: 20px;
  }

  .title {
    flex-grow: 1;
    text-align: left;
  }

  .navLinks {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .navLink {
    position: relative;
    margin-right: 40px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-weight: 500;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: -10px; // 10px margin below the text
      left: 0;
      background-color: white;
      transition: width 0.3s ease;
    }

    &:hover::after {
      width: 100%; // Full width of the parent element
    }

    &:last-child {
      margin-right: 100px; // Increased space between the last menu item and the shopping basket
    }
  }

  .basket {
    display: flex;
    align-items: center;
    margin-right: 5rem;
    color: white;

    .MuiBadge-badge {
      margin-right: 5px;
    }

    .MuiTypography-root {
      margin-left: 5px;
    }
  }

  .languageSwitch {
    margin-right: 20px;
    color: white;
    cursor: pointer;

    span {
      margin: 0 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .logo-mobile {
    width: 130px; // Adjust the size of the logo as needed
    position: absolute;
    margin-top: 20px;
    top: 50%; // Start the logo in the middle vertically
    transform: translateY(-50%); // Move the logo up by half its height to make it centered over the menu and image
    left: 0px; // Position it from the right, adjust as needed
  }
  .basket-mobile {
    display: flex;
    color: white;
  }

  .basket-mobile .MuiTypography-root {
    margin-right: 2rem;
    color: #3f3f3f;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 0.8rem; /* Smaller text size for the price */
    .MuiBadge-badge {
      margin-right: 5px;
    }

    .MuiTypography-root {
      margin-left: 5px;
    }
  }
}
.logo {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 250px;
  height: 140px;
  z-index: 10001;
  opacity: 0; // Start with logo hidden
  animation: fadeIn 2s forwards; // Apply the animation on load
}

// @keyframes slideDownFade {
//   0% {
//     opacity: 1;
//     transform: translateY(0);
//   }
//   100% {
//     opacity: 0;
//     transform: translateY(100%);
//   }
// }

// .app-bar {
//   background-color: #00BFFF;
//   position: sticky;
//   top: 0;
//   z-index: 1000;
//   animation: slideDownFade 2s forwards; // Adjust timing as needed
// }

// .app-bar.hidden {
//   opacity: 0;
//   transform: translateY(100%);
//   animation: none; // Stop animation when hidden
// }

// .nav-links {
//   color: white;
//   position: relative;
//   z-index: 1001; // Above the image
// }
