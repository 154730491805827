.chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;
  
    .chat-toggle {
      background-color: #1976d2;
      width: 50px;
      height: 50px;
      color: white;
      &:hover {
        background-color: #1565c0;
      }
    }
  
    .chat-box {
      width: 300px;
      max-height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      margin-top: 10px;
    }
  
    .chat-title {
      padding: 10px;
      background-color: #1976d2;
      color: white;
      text-align: center;
    }
  
    .chat-messages {
      flex: 1;
      padding: 10px;
      overflow-y: auto;
      height: auto;
      min-height: 100px;
    }
  
    .chat-input {
      display: flex;
      padding: 10px;
      border-top: 1px solid #ddd;
  
      .MuiTextField-root {
        margin-right: 10px;
      }
  
      .MuiButton-root {
        flex-shrink: 0;
      }
    }
  }