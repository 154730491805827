.about-flight {
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
  
    h2 {
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-size: 1.5rem;
      margin-bottom: 10px;
      text-align: center;
      // margin-left: var(--left-spacing);
    }
    .text-container{
        padding-left: 60px;
        padding-right: 240px;
        @media (max-width: 600px) { 
          padding-left: 20px;
          padding-right: 20px;
        }
    }
    .icon {
      margin-right: 10px;
    }
  
    p {
      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      margin-bottom: 10px;
      // margin-left: var(--left-spacing);
      padding-left: 20px;
    }
  
    ul {
      list-style-type: disc;
      padding-left: 20px;
  
      @media (max-width: 600px) { // Adjust the max-width according to your breakpoint
        padding-left: 20px; /* Align list items to the left */
        text-align: left; /* Ensure the list itself is aligned to the left */
      }
  
      li {
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        margin-bottom: 10px;
  
        strong {
          font-weight: bold;
        }
  
        @media (max-width: 600px) { // Adjust the max-width according to your breakpoint
          text-align: left; /* Align text inside list items to the left */
        }
      }
    }
    // Center text for mobile devices
    &.center-text {
      text-align: center;
  
      h2 {
        justify-content: center;
      }
  
      p {
        text-align: center;
      }
  
      .text-container {
        ul {
          li {
            text-align: center;
          }
        }
      }
    }
  }
